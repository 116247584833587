import React from 'react';
import {
    BooleanInput,
    Edit,
    SaveButton,
    SimpleForm,
    TextInput,
    TextField,
    Toolbar,
    ImageInput,
    ImageField,
    EmailField,
} from 'react-admin';
import S3ImageField from '../../field-components/S3ImageField';

const ForgotEditToolbar = (props: any) => (
    <Toolbar {...props}>
        <SaveButton label='Update Expert' />
    </Toolbar>
);

export const ExpertEdit = (props: any) => (

    <Edit {...props}>
        <SimpleForm toolbar={<ForgotEditToolbar />}>

            <TextField source="expertProfile.name" label="Name" />
            <EmailField source="email" />
            <BooleanInput source="expertProfile.online" label="Online" />
            <BooleanInput source="expertProfile.featured" label="Trending" />
            <TextInput source="expertProfile.owner.userId" label="Owner" />
            <S3ImageField source="expertProfile.profilePic" label="Current Profile Pic" />
            <ImageInput source="image" label="Expert Profile Picture" accept="image/jpeg">
                <ImageField source="image" title="profilePic" />

            </ImageInput>
        </SimpleForm>
    </Edit>
);


