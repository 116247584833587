import React from 'react';
import { Create, SimpleForm, NumberInput, required } from 'react-admin';

export const AppConfigCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            
            <NumberInput source="bookingAcceptTimeInMin" label="Booking Accept Time in mins" validate={[required()]} />
           
        </SimpleForm>
    </Create>
);
