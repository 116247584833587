import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, UserMenu, MenuItemLink } from 'react-admin';
import PersonIcon from '@material-ui/icons/Person';
import { environment } from '../config/config';

const CustomUserMenu = (props) => (
    <UserMenu {...props}>
        {/* <MenuItemLink to="/profile" primaryText="Profile" leftIcon={<PersonIcon />} /> */}
    </UserMenu>
);

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const CustomAppBar = (props) => {
    const classes = useStyles();
    return (
        <AppBar {...props} userMenu={<CustomUserMenu />}>
            <div className="env-label" style={{ color: '#000' }}>
                {environment}
            </div>
            <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
            <span className={classes.spacer} />
        </AppBar>
    );
};

export default CustomAppBar;
