import * as React from 'react';
import { Show, SimpleShowLayout, TextField, ArrayField, ReferenceField, Datagrid } from 'react-admin';
import { ResourceName } from '../../config/config';

export const CreditShow = (props: any) => (
    <Show {...props} hasEdit={false}>
        <SimpleShowLayout>
            <ArrayField source="customerProfile.bookings">
                <Datagrid>
                    <ReferenceField label="BookingId" source="bookingId" reference={ResourceName.bookings} link="show">
                        <TextField source="bookingId" />
                    </ReferenceField>
                    <TextField source="creditsSpent" label="Credits Spent" />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);
