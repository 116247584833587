import {
    BooleanInput,
    Edit,
    NumberInput,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useDataProvider,
} from 'react-admin';
import React, { useEffect, useState } from 'react';
import { ResourceName } from '../../config/config';


const ServiceEditToolbar = (props: any) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

const renderOption = (choice: any) => `${choice.name}`;

export const ServiceEdit = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(true);
    const dataProvider = useDataProvider();
    const [secondaryCategories, setSecondaryCategories] = useState([]);

    const getOwnerSecondaryCategoriesByServiceId = (serviceId: string) => {
        dataProvider
            .getOne(ResourceName.services, {
                id: serviceId,
            })
            .then(({ data }: any) => {
                if (data?.owner?.secondaryCategories) {
                    const secondaryCategory = data?.owner?.secondaryCategories?.map((category: any, i: any) => {
                        return { id: category.key, name: category.displayName };
                    });
                    setSecondaryCategories(secondaryCategory);
                }
            })
            .catch(() => {
                setLoading(false);
                setError(true);
            });
    };

    useEffect(() => {
        getOwnerSecondaryCategoriesByServiceId(props.id);
    }, []);

    return (
        <Edit {...props}>
            <SimpleForm toolbar={<ServiceEditToolbar />}>
                <BooleanInput source='featured' label='Featured' />
                <NumberInput source='featuredRank' label='Featured Rank' />

                <SelectInput
                    source='status'
                    choices={[
                        { id: 'ACTIVE', name: 'ACTIVE' },
                        { id: 'INACTIVE', name: 'INACTIVE' },
                        { id: 'PENDING_APPROVAL', name: 'PENDING_APPROVAL' },
                    ]}
                />
                <SelectInput source='category.key' choices={secondaryCategories} optionText={renderOption} />
                <TextInput multiline source='details' label='Details' />
            </SimpleForm>
        </Edit>
    );
};
