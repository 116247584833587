import React from 'react';

import {
    BooleanField,
    Show,
    TextField,
    TabbedShowLayout,
    Tab,
    FunctionField,
    EmailField,
    NumberField,
} from 'react-admin';

const getStartMinAndHour = (startTimeInMin: number, startTimeInHours: number) => {
    if (startTimeInMin > 0 && startTimeInMin < 60) {
        if (startTimeInHours < 10) {
            return `From:  0${startTimeInHours} : ${startTimeInMin} h`;
        }
        return `From: ${startTimeInHours} : ${startTimeInMin} h`;
    } else if (!startTimeInMin) {
        return `From: ${startTimeInHours}h`;
    }
};
const getEndMinAndHour = (endTimeInMin: number, endTimeInHours: number) => {
    if (endTimeInMin > 0 && endTimeInMin < 60) {
        if (endTimeInHours < 10) {
            return `To:  0${endTimeInHours} : ${endTimeInMin} h`;
        }
        return `To: ${endTimeInHours} : ${endTimeInMin} h`;
    } else if (!endTimeInMin) {
        return `To  : ${endTimeInHours}h`;
    }
};

export const ExpertShow = (props: any) => (
    <Show {...props}>
        <TabbedShowLayout syncWithLocation>
            <Tab label='Personal Details'>
                <TextField source='expertProfile.name' label='Name' />
                <EmailField source='email' />
                <BooleanField source='expertProfile.online' label='Online' />
                <BooleanField source='expertProfile.featured' label='Trending' />
            </Tab>

            <Tab label='Bookings Details'>
                <NumberField source='createdServicesCount' label='Created Services' />
                <NumberField source='expertProfile.completedBookingsCount' label='Completed Bookings' />
                <NumberField source='expertProfile.acceptedBookingsCount' label='Accepted Bookings' />
                <NumberField source='expertProfile.rejectedBookingsCount' label='Rejected Bookings' />
                <NumberField source='expertProfile.pendingBookingsCount' label='Pending bookings' />
                <NumberField source='expertProfile.voidedBookingsCount' label='Voided Bookings' />
                <NumberField source='expertProfile.chargeFailedBookingsCount' label='Charge Failed Bookings' />
            </Tab>
            
            <Tab label='Availability Details'>
                <TextField source='expertProfile.availability.timezone' label='availability Zone' sortable={false} />
                <TabbedShowLayout syncWithLocation>
                    <Tab label='Availability Slots'>
                        <FunctionField
                            label='Monday'
                            render={(record: any) => {
                                if (!record || !record.expertProfile?.availability?.monday) {
                                    return 'Expert is not available on this day';
                                }
                                try {
                                    return record.expertProfile?.availability?.monday?.map((availability: any) => {
                                        const startTimeInHours = Math.floor(availability.start / 60);
                                        const startTimeInMin = availability.start % 60;
                                        const endTimeInHours = Math.floor(availability.end / 60);
                                        const endTimeInMin = availability.end % 60;

                                        return [
                                            getStartMinAndHour(startTimeInMin, startTimeInHours) +
                                                ' ' +
                                                getEndMinAndHour(endTimeInMin, endTimeInHours),
                                        ];
                                    });
                                } catch (error) {}
                                return '';
                            }}
                        />
                        <FunctionField
                            label='Tuesday'
                            render={(record: any) => {
                                if (!record || !record.expertProfile?.availability?.tuesday) {
                                    return 'Expert is not available on this day';
                                }
                                try {
                                    return record.expertProfile?.availability?.tuesday?.map((availability: any) => {
                                        const startTimeInHours = Math.floor(availability.start / 60);
                                        const startTimeInMin = availability.start % 60;
                                        const endTimeInHours = Math.floor(availability.end / 60);
                                        const endTimeInMin = availability.end % 60;
                                        return [
                                            getStartMinAndHour(startTimeInMin, startTimeInHours) +
                                                ' ' +
                                                getEndMinAndHour(endTimeInMin, endTimeInHours),
                                        ];
                                    });
                                } catch (error) {}
                                return '';
                            }}
                        />
                        <FunctionField
                            label='Wednesday'
                            render={(record: any) => {
                                if (!record || !record.expertProfile?.availability?.wednesday) {
                                    return 'Expert is not available on this day';
                                }
                                try {
                                    return record.expertProfile?.availability?.wednesday?.map((availability: any) => {
                                        const startTimeInHours = Math.floor(availability.start / 60);
                                        const startTimeInMin = availability.start % 60;
                                        const endTimeInHours = Math.floor(availability.end / 60);
                                        const endTimeInMin = availability.end % 60;
                                        return [
                                            getStartMinAndHour(startTimeInMin, startTimeInHours) +
                                                ' ' +
                                                getEndMinAndHour(endTimeInMin, endTimeInHours),
                                        ];
                                    });
                                } catch (error) {}
                                return '';
                            }}
                        />
                        <FunctionField
                            label='Thursday'
                            render={(record: any) => {
                                if (!record || !record.expertProfile?.availability?.thursday) {
                                    return 'Expert is not available on this day';
                                }
                                try {
                                    return record.expertProfile?.availability?.thursday?.map((availability: any) => {
                                        const startTimeInHours = Math.floor(availability.start / 60);
                                        const startTimeInMin = availability.start % 60;
                                        const endTimeInHours = Math.floor(availability.end / 60);
                                        const endTimeInMin = availability.end % 60;
                                        return [
                                            getStartMinAndHour(startTimeInMin, startTimeInHours) +
                                                ' ' +
                                                getEndMinAndHour(endTimeInMin, endTimeInHours),
                                        ];
                                    });
                                } catch (error) {}
                                return '';
                            }}
                        />
                        <FunctionField
                            label='Friday'
                            render={(record: any) => {
                                if (!record || !record.expertProfile?.availability?.friday) {
                                    return 'Expert is not available on this day';
                                }
                                try {
                                    return record.expertProfile?.availability?.friday?.map((availability: any) => {
                                        const startTimeInHours = Math.floor(availability.start / 60);
                                        const startTimeInMin = availability.start % 60;
                                        const endTimeInHours = Math.floor(availability.end / 60);
                                        const endTimeInMin = availability.end % 60;
                                        return [
                                            getStartMinAndHour(startTimeInMin, startTimeInHours) +
                                                ' ' +
                                                getEndMinAndHour(endTimeInMin, endTimeInHours),
                                        ];
                                    });
                                } catch (error) {}
                                return '';
                            }}
                        />
                        <FunctionField
                            label='Saturday'
                            render={(record: any) => {
                                if (!record || !record.expertProfile?.availability?.saturday) {
                                    return 'Expert is not available on this day';
                                }
                                try {
                                    return record.expertProfile?.availability?.saturday?.map((availability: any) => {
                                        const startTimeInHours = Math.floor(availability.start / 60);
                                        const startTimeInMin = availability.start % 60;
                                        const endTimeInHours = Math.floor(availability.end / 60);
                                        const endTimeInMin = availability.end % 60;
                                        return [
                                            getStartMinAndHour(startTimeInMin, startTimeInHours) +
                                                ' ' +
                                                getEndMinAndHour(endTimeInMin, endTimeInHours),
                                        ];
                                    });
                                } catch (error) {}
                                return '';
                            }}
                        />
                        <FunctionField
                            label='Sunday'
                            render={(record: any) => {
                                if (!record || !record.expertProfile?.availability?.sunday) {
                                    return 'Expert is not available on this day';
                                }
                                try {
                                    return record.expertProfile?.availability?.sunday?.map((availability: any) => {
                                        const startTimeInHours = Math.floor(availability.start / 60);
                                        const startTimeInMin = availability.start % 60;
                                        const endTimeInHours = Math.floor(availability.end / 60);
                                        const endTimeInMin = availability.end % 60;
                                        return [
                                            getStartMinAndHour(startTimeInMin, startTimeInHours) +
                                                ' ' +
                                                getEndMinAndHour(endTimeInMin, endTimeInHours),
                                        ];
                                    });
                                } catch (error) {}
                                return '';
                            }}
                        />
                    </Tab>
                </TabbedShowLayout>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
