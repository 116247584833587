import React from 'react';
import {
    Show,
    TextField,
    EmailField,
    BooleanField,
    DateField,
    NumberField,
    TabbedShowLayout,
    Tab,
    UrlField,
    ReferenceField,
    FunctionField,
} from 'react-admin';
import S3ImageField from '../../field-components/S3ImageField';
import { ResourceName } from '../../config/config';

export const UserShow = (props: any) => (
    <Show {...props} hasEdit={true}>
        <TabbedShowLayout>
            <Tab label="general">
                <TextField source="id" label="User ID" />
                <EmailField source="email" />
                <BooleanField source="isVerified" label="Email Verified" />
                <BooleanField source="isOwner" label="Owner" />
                <BooleanField source="isExpert" label="Expert" />
                <TextField source="authentication" label="Auth Type" />
                <DateField source="joinedDate" showTime={true} />
                <ReferenceField
                    label="Forgot Password Email"
                    source="email"
                    reference={ResourceName.forgot}
                    link="edit"
                >
                    <FunctionField render={(record: any) => `Send forgot password email to ${record?.email}`} />
                </ReferenceField>
                <NumberField source="forgotPasswordEmailCount" label="Forgot Password Email Count" />
                <DateField
                    source="forgotPasswordCreatedDate"
                    label="Forgot Password Code Created Date"
                    showTime={true}
                />
                <TextField source="referralCode" label="Referral Code" />
            </Tab>
            <Tab label="customer profile" path="customer">
                <TextField source="customerProfile.name" label="Name" />
                <TextField source="customerProfile.phoneNumber" label="Phone" />
                <S3ImageField source="customerProfile.profilePic" label="Profile Pic" />
                <TextField source="customerProfile.occupation" label="Occupation" />
                <TextField multiline source="customerProfile.bio" label="Bio" />
                <TextField source="customerProfile.country" label="Country" />
                <TextField source="customerProfile.stripeCustomerId" label="Stripe Customer ID" />
                <BooleanField source="customerProfile.online" label="Online" />
            </Tab>
            <Tab label="expert profile" path="expert">
                <TextField source="expertProfile.name" label="Name" />
                <TextField source="expertProfile.username" label="Username" />
                <TextField source="expertProfile.phoneNumber" label="Phone" />
                <S3ImageField source="expertProfile.profilePic" label="Profile Pic" />
                <ReferenceField
                    label="Owner"
                    source="expertProfile.owner.userId"
                    reference={ResourceName.users}
                    link="show"
                >
                    <TextField source="ownerProfile.name" />
                </ReferenceField>
                <TextField source="expertProfile.occupation" label="Occupation" />
                <TextField multiline source="expertProfile.bio" label="Bio" />
                <BooleanField source="expertProfile.online" label="Online" />
                <NumberField source="expertProfile.yearsOfExperience" label="Years of Experience" />
                <TextField source="expertProfile.addressLine1" label="Address Line 1" />
                <TextField source="expertProfile.addressLine2" label="Address Line 2" />
                <TextField source="expertProfile.city" label="City" />
                <TextField source="expertProfile.stateProvinceRegion" label="State/Province/Region" />
                <TextField source="expertProfile.country" label="Country" />
                <NumberField source="expertProfile.rating" label="Rating" />
                <NumberField source="expertProfile.ratingCount" label="Rating Count" />
            </Tab>
            <Tab label="owner profile" path="owner">
                <TextField source="ownerProfile.name" label="Name" />
                <TextField source="ownerProfile.username" label="User Name" />
                <TextField source="ownerProfile.phoneNumber" label="Phone" />
                <S3ImageField source="ownerProfile.businessLogo" label="Business Logo" />
                <ReferenceField
                    label="Category"
                    source="ownerProfile.category.key"
                    reference={ResourceName.categories}
                    link="show"
                >
                    <TextField source="displayName" />
                </ReferenceField>
                <UrlField source="ownerProfile.website" label="Website" />
                <TextField source="ownerProfile.type" label="Owner Type" />
                <TextField multiline source="ownerProfile.about" label="About" />
                <NumberField source="ownerProfile.rating" label="Rating" />
                <NumberField source="ownerProfile.ratingCount" label="Rating Count" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);
