import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    NumberField,
    ReferenceField,
    Filter,
    SearchInput,
    FunctionField,
    SelectInput,
    ChipField,
} from 'react-admin';
import { ResourceName } from '../../config/config';
import { PageResults } from './../../pageResults/PageResults';

export const ServicesList = (props: any) => (
    <List
        exporter={false}
        bulkActionButtons={false}
        pagination={<PageResults />}
        perPage={25}
        filters={
            <Filter>
                <SearchInput placeholder="Search Service" source="search" alwaysOn />
                <SearchInput placeholder="Service ID" source="serviceId" alwaysOn />
                <SearchInput placeholder="Owner ID" source="ownerId" />
                <SearchInput placeholder="Expert ID" source="expertId" />
                <SelectInput
                    source="status"
                    choices={[
                        { id: 'ACTIVE', name: 'ACTIVE' },
                        { id: 'INACTIVE', name: 'INACTIVE' },
                        { id: 'PENDING_APPROVAL', name: 'PENDING APPROVAL' },
                    ]}
                    alwaysOn
                />

            </Filter>
        }
        sort={{ field: 'createdDate', order: 'DESC' }}
        {...props}
    >
        <Datagrid rowClick="show">
            <TextField source="serviceId" />
            <TextField source="title" />
            <ChipField source="type" sortable={false} />
            <ChipField source="status" sortable={false} />
            <FunctionField
                label="Price"
                render={(record: any) => `${record.price / 100}`}
                options={{ maximumFractionDigits: 2 }}
                sortable={false}
            />
            <ReferenceField label="Category" source="category.key" reference={ResourceName.categories} link="show">
                <TextField source="displayName" />
            </ReferenceField>
            <ReferenceField label="Owner" source="ownerId" reference={ResourceName.users} link="show">
                <TextField source="ownerProfile.name" />
            </ReferenceField>
            <NumberField source="rating" sortable={false} />
            <NumberField source="ratingCount" label="Rating Count" sortable={false} />
            <BooleanField source="featured" label="Featured" sortable={false} />
            <NumberField source="featuredRank" label="Featured Rank " sortable={false} />
        </Datagrid>
    </List>
);
