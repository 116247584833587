import { BaseResourceDataProvider } from '../baseResourceDataProvider';
import { DataProviderParams } from '../DataProvider';
import { ResourceName } from '../../config/config';
import { stringify } from 'query-string';

class CreditDataProvider extends BaseResourceDataProvider {
    constructor() {
        super();
        this.resourceName = ResourceName.credits;
    }

    getList(params: DataProviderParams): Promise<any> {
        const { page, perPage } = params.pagination || { page: 1, perPage: 10 };
        const { userId } = params.filter;
        let { field, order } = params.sort;

        const query = userId
            ? {
                  skip: (page - 1) * perPage,
                  take: perPage,
                  userId: userId,
                  sortKey: field || undefined,
                  sortOrder: order || undefined,
              }
            : {
                  skip: (page - 1) * perPage,
                  take: perPage,
                  sortKey: field || undefined,
                  sortOrder: order || undefined,
              };

        return new Promise((resolve, reject) => {
            this.getHttpClient(`?${stringify(query)}`)
                .then(({ json }: any) => {
                    resolve({
                        data:
                            json.credits.map((credit: any) => ({
                                ...credit,
                                id: credit.userId,
                            })) || [],
                        total: json.totalCount || 0,
                    });
                })
                .catch(reject);
        });
    }

    getOne(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`?userId=${params.id}`)
                .then(({ json }: any) => {
                    resolve({ data: { ...json, id: json.userId } });
                })
                .catch(reject);
        });
    }
}

export default new CreditDataProvider();
