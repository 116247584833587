import React from 'react';
import { List, Datagrid, TextField, Filter, SearchInput, FunctionField } from 'react-admin';
import { PageResults } from './../../pageResults/PageResults';

export const CreditList = (props: any) => (
    <List
        {...props}
        exporter={false}
        bulkActionButtons={false}
        pagination={<PageResults />}
        perPage={25}
        filters={
            <Filter>
                <SearchInput placeholder="User ID" source="userId" alwaysOn />
            </Filter>
        }
        sort={{ field: 'userId', order: 'ASC' }}
    >
        <Datagrid rowClick="show">
            <TextField source="userId" label="UserId" />
            <TextField source="email" label="Email" />
            <FunctionField
                label="Free Credits"
                render={(record: any) => `${record.customerProfile.creditBalance / 100}`}
                options={{ maximumFractionDigits: 2 }}
                sortable={true}
            />
            <FunctionField
                label="Amount Used"
                render={(record: any) => `${record.customerProfile.creditUsed / 100}`}
                options={{ maximumFractionDigits: 2 }}
                sortable={false}
            />
        </Datagrid>
    </List>
);
