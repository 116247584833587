import React from 'react';
import { Show, TextField, SimpleShowLayout, FunctionField } from 'react-admin';

export const CategoryShow = (props: any) => (
    <Show {...props} hasEdit={true}>
        <SimpleShowLayout>
            <TextField source="key" label="Key" />
            <TextField source="displayName" label="Display Name" />
            <FunctionField
                label="Meta Data"
                render={(record: any) => {
                    if (!record || !record.meta) {
                        return '';
                    }
                    try {
                        return JSON.stringify(record.meta);
                    } catch (error) {}
                    return '';
                }}
            />
        </SimpleShowLayout>
    </Show>
);
