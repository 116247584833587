import React from 'react';
import {
    Show,
    TextField,
    SimpleShowLayout,
    BooleanField,
    NumberField,
    ReferenceField,
    FunctionField,
} from 'react-admin';
import { ResourceName } from '../../config/config';
import AvailabilityField from '../../field-components/availabilityField';
import S3ImageField from '../../field-components/S3ImageField';

export const ServiceShow = (props: any) => (
    <Show {...props} hasEdit={true}>
        <SimpleShowLayout>
            <TextField source="serviceId" label="Service ID" />
            <TextField source="title" label="Title" />
            <TextField source="serviceName" label="Service Name" />
            <ReferenceField label="Owner" source="owner.userId" reference={ResourceName.users} link="show">
                <TextField source="ownerProfile.name" />
            </ReferenceField>
            <ReferenceField label="Expert" source="expert.userId" reference={ResourceName.users} link="show">
                <TextField source="expertProfile.name" />
            </ReferenceField>
            <ReferenceField label="Category" source="category.key" reference={ResourceName.categories} link="show">
                <TextField source="displayName" />
            </ReferenceField>
            <S3ImageField source="image" label="Service Image" />
            <TextField source="status" label="Status" />
            <ReferenceField
                label="Reject Service"
                source="serviceId"
                reference={ResourceName.rejectServices}
                link="edit"
            >
                <FunctionField render={(record: any) => 'Click here to send reject email'} />
            </ReferenceField>
            <TextField multiline source="details" label="Details" />
            <TextField source="currency" label="Currency" />
            <FunctionField
                label="Price"
                render={(record: any) => `${record.price / 100}`}
                options={{ maximumFractionDigits: 2 }}
            />
            <NumberField source="rating" label="Rating" />
            <NumberField source="ratingCount" label="Rating Count" />
            <BooleanField source="featured" label="Featured" />
            <NumberField source="featuredRank" label="Featured Rank " />
            <AvailabilityField source="expert" record />
            <FunctionField
                multiline
                label="Scheduled time slots"
                render={(record: any) => {
                    if (!record || !record.bookings) {
                        return '';
                    }
                    try {
                        return (
                            <>
                                {record.bookings.map((booking: any) => (
                                    <div key={booking.bookingId}>
                                        {JSON.stringify(new Date(booking.scheduledTime).toLocaleString())}
                                    </div>
                                ))}
                            </>
                        );
                    } catch (error) {}
                    return '';
                }}
            />
        </SimpleShowLayout>
    </Show>
);
