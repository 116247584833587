import React, { useState, useEffect } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    DateTimeInput,
    FormDataConsumer,
    SimpleShowLayout,
    FunctionField,
    useDataProvider,
    Loading,
} from 'react-admin';
import { ResourceName } from '../../config/config';
const PendingPayoutView = ({ formData }: any) => {
    const ownerIdInput = formData ?.ownerId;
    const startTimeInput = formData ?.startTime;
    const endTimeInput = formData ?.endTime;

    const ownerId = ownerIdInput && ownerIdInput.length === 10 ? ownerIdInput : null;
    const startTimeMillis =
        startTimeInput && typeof startTimeInput === 'object' && !isNaN(startTimeInput.getTime())
            ? startTimeInput.getTime()
            : null;
    const endTimeMillis =
        endTimeInput && typeof endTimeInput === 'object' && !isNaN(endTimeInput.getTime())
            ? endTimeInput.getTime()
            : null;

    const dataProvider = useDataProvider();
    const [pendingPayout, setPendingPayout] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(true);

    useEffect(() => {
        const abortController = new AbortController();

        if (ownerId) {
            setError(false);
            setLoading(true);
            dataProvider
                .getOne(ResourceName.pendingPayouts, {
                    id: ownerId,
                    filter: {
                        startTimeMillis: startTimeMillis || undefined,
                        endTimeMillis: endTimeMillis || undefined,
                    },
                })
                .then(({ data }: any) => {
                    setPendingPayout(data);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                    setError(true);
                });
        }

        return () => {
            abortController.abort();
        };
    }, [ownerId, startTimeMillis, endTimeMillis, dataProvider]);

    if (loading) return <Loading />;
    if (error) return <div>Error</div>;
    if (pendingPayout && ownerIdInput && ownerIdInput.length === 10) {
        return (
            <SimpleShowLayout record={pendingPayout}>
                <FunctionField label="Gross" render={(record: any) => `${record.amountBeforeCommission / 100}`} options={{ maximumFractionDigits: 2 }} />
                <FunctionField label="commission" render={(record: any) => `${record.commission / 100}`} options={{ maximumFractionDigits: 2 }} />
                <FunctionField label="amount" render={(record: any) => `${record.amount / 100}`} options={{ maximumFractionDigits: 2 }} />
                <FunctionField
                    multiline
                    label="Bookings"
                    render={(record: any) =>
                        `${
                        record ?.earnings ?.length > 0
                            ? record ?.earnings.map((earning: any) => earning ?.booking ?.bookingId).join(', ')
                                : ''
                        }`
                    }
                />
            </SimpleShowLayout>
        );
    }
    return null;
};

export const PayoutCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="ownerId" label="Owner ID" required />
            <DateTimeInput source="startTime" label="Start time" />
            <DateTimeInput source="endTime" label="End time" />
            <FormDataConsumer>{({ formData }: any) => <PendingPayoutView formData={formData} />}</FormDataConsumer>
        </SimpleForm>
    </Create>
);
