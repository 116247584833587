import { BaseResourceDataProvider } from '../baseResourceDataProvider';
import { DataProviderParams } from '../DataProvider';
import { ResourceName } from '../../config/config';
import { stringify } from 'query-string';

class ExpertDataProvider extends BaseResourceDataProvider {
    constructor() {
        super();
        this.resourceName = ResourceName.experts;
    }

    getList(params: DataProviderParams): Promise<any> {
        const { page, perPage } = params?.pagination || { page: 1, perPage: 10 };
        const { userId, name } = params.filter;
        let { field, order } = params.sort;

        const query = userId
            ? {
                  skip: (page - 1) * perPage,
                  take: perPage,
                  userId: userId,
                  name: name || undefined,
                  sortKey: field || undefined,
                  sortOrder: order || undefined,
              }
            : {
                  skip: (page - 1) * perPage,
                  take: perPage,
                  name: name || undefined,
                  sortKey: field || undefined,
                  sortOrder: order || undefined,
              };

        return new Promise((resolve, reject) => {
            this.getHttpClient(`?${stringify(query)}`)
                .then(({ json }: any) => {
                    resolve({
                        data:
                            json.experts.map((expert: any) => ({
                                ...expert,
                                id: expert.userId,
                            })) || [],
                        total: json.totalCount || 0,
                    });
                })
                .catch(reject);
        });
    }

    getOne(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`?userId=${params.id}`)
                .then(({ json }: any) => {
                    resolve({ data: { ...json, id: json.userId } });
                })
                .catch(reject);
        });
    }

    update(params: DataProviderParams): Promise<any> {
        if (params.data.image) {
            const formData = new FormData();
            for (const key in params.data.image) {
                formData.append('image', params.data.image[key]);
            }

            new Promise((resolve, reject) => {
                this.getHttpClient(`${params.id}/profile-pic`, {
                    method: 'POST',
                    body: formData,
                })
                    .then((response: any) => {
                        resolve({ data: {} });
                    })
                    .catch(reject);
            });
        }

        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.id}`, {
                method: 'PUT',
                body: JSON.stringify({
                    online: params.data.expertProfile.online,
                    featured: params.data.expertProfile.featured,
                    ownerId: params?.data?.expertProfile?.owner?.userId || undefined,
                }),
            })
                .then((response: any) => {
                    resolve({ data: {} });
                })
                .catch(reject);
        });
    }
}

export default new ExpertDataProvider();
