const ENVIRONMENT = process.env.REACT_APP_ENV;

export const isProd = ENVIRONMENT === 'production';
export const isDemo = ENVIRONMENT === 'demo';
export const environment = `${ENVIRONMENT} environment`;

export const API_URL = isProd
    ? 'https://api.serw.io/v1/admin'
    : ENVIRONMENT === 'demo'
    ? 'https://api-curveup-demo.expertrepublic.com/v1/admin'
    : 'https://stage-api.serw.io/v1/admin';

export const AWS_URL = isProd
    ? 'https://serwdev.s3.us-east-2.amazonaws.com/'
    : ENVIRONMENT === 'demo'
    ? 'https://curveup-demo.s3.eu-central-1.amazonaws.com/'
    : 'https://serwstage.s3.us-east-2.amazonaws.com/';

export const AWS_S3_CONSOLE_URL = isProd
    ? 'https://s3.console.aws.amazon.com/s3/buckets/serwdev/'
    : ENVIRONMENT === 'demo'
    ? 'https://s3.console.aws.amazon.com/s3/buckets/curveup-demo'
    : 'https://s3.console.aws.amazon.com/s3/buckets/serwstage/';

export enum ResourceName {
    services = 'services',
    users = 'users',
    bookings = 'bookings',
    categories = 'categories',
    currencies = 'currencies',
    exchangeRates = 'exchange-rates',
    payouts = 'payouts',
    pendingPayouts = 'pending-payouts',
    payments = 'payments',
    callLogs = 'call-logs',
    devices = 'devices',
    forgot = 'forgot',
    rejectServices = 'reject-services',
    referrals = 'referrals',
    expertApplications = 'expert-applications',
    notifications = 'notifications',
    dashboards = 'dashboards',
    chats = 'chats',
    credits = 'credits',
    experts = 'experts',
    admins = 'admins',
    appConfigs = 'app-configs',
}

export enum Role {
    ROOT = 'ROOT',
    ADMIN = 'ADMIN',
}
