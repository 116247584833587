import React from 'react';
import { Edit, SimpleForm, TextInput, required } from 'react-admin';

export const CategoryEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="displayName" label="Display Name" validate={[required()]} />
            <TextInput
                multiline
                resettable
                source="meta"
                label="Meta Data (JSON)"
                format={(meta: any) => {
                    if (typeof meta === 'object') {
                        if (meta === null) {
                            return '{}';
                        }
                        try {
                            return JSON.stringify(meta);
                        } catch (error) {
                            return '{}';
                        }
                    }
                    return meta;
                }}
                validate={[required()]}
            />
        </SimpleForm>
    </Edit>
);
